import { DEFAULT_CENTER_LNG, DEFAULT_CENTER_LAT } from '../../constants.js';

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Button, Container, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Field } from 'redux-form';
import { updateExplorer3dSettings } from 'redux/actions';
import { updatePartialExplorer3dSettings } from 'shared/utils/Explorer3dApi';

import renderField from '../../shared/components/form/Field';

const defaultAlertData = {
  isShown: false,
  bodyText: '',
  closeBtnText: '',
  handleClickCloseBtn: () => null,
};

const MapSettingsPopup = ({ closeEditor, opened }) => {
  const [alertData, setAlertData] = useState(defaultAlertData);
  const [tablesData, setTablesData] = useState(null);
  const dataCSV = useSelector(state => state.editorCSV.dataCSV);
  const dispatch = useDispatch();
  const settings = useSelector(state => state.explorer3d.current.settings);
  const [successAlert, setSuccessAlert] = useState('');
  const [drawPolygonButtonStep, setDrawPolygonButtonStep] = useState(0);
  const [polygonHeight, setPolygonHeight] = useState(0);
  const [drawPolygonButtonStepStyle, setDrawPolygonButtonStepStyle] =
    useState('btn-primary');

  useEffect(() => {
    if (JSON.parse(settings.polygonCoordinates)?.length) {
      setDrawPolygonButtonStep(2);
      setDrawPolygonButtonStepStyle('btn-danger');
    }
  }, [settings.polygonCoordinates]);

  useEffect(() => {
    if (!tablesData && dataCSV) {
      setTablesData(() => structuredClone(dataCSV));
    }
  }, [dataCSV, tablesData]);

  const handleCloseAlert = useCallback(() => {
    setAlertData(() => defaultAlertData);
  }, []);

  const getDrawPolygonButtonLabel =
    drawPolygonButtonStep === 0
      ? 'Draw Polygon'
      : drawPolygonButtonStep === 1
        ? 'Build Polygon'
        : 'Reset Polygon';

  const iframeRef = useRef(null);
  const polygonAltitudeRef = useRef(null);
  const [polygonCoordinatesLength, setPolygonCoordinatesLength] = useState();

  useEffect(() => {
    if (iframeRef.current?.contentDocument) {
      setTimeout(() => {
        iframeRef.current?.contentWindow?.postMessage({
          message: {
            isInitial: true,
            centerPoint: settings.centerPoint,
            mainColor: settings.mainColor,
            distance: settings.distance,
            horizonAngle: settings.horizonAngle,
            verticalAngle: settings.verticalAngle,
            polygonCoordinates: JSON.parse(settings.polygonCoordinates),
            altitude: settings.altitude,
          },
          targetOrigin: '*',
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iframeRef.current?.contentDocument, settings.centerPoint]);

  useEffect(() => {
    let timeoutId;
    async function getChildMessage(event) {
      if (event.data === 'Clear Coordinates') {
        dispatch(
          updateExplorer3dSettings({
            ...settings,
            polygonCoordinates: JSON.stringify([]),
          })
        );
      }

      if (
        (event.data.heading || event.data.heading === 0) &&
        (event.data.tilt || event.data.tilt === 0) &&
        (event.data.range || event.data.range === 0)
      ) {
        const newParams = {
          verticalAngle: Math.round(event.data.tilt),
          horizonAngle: Math.round(event.data.heading),
          distance: Math.round(event.data.range),
        };

        await updatePartialExplorer3dSettings({
          id: settings.id,
          ...newParams,
        });

        dispatch(
          updateExplorer3dSettings({
            ...settings,
            ...newParams,
          })
        );

        setSuccessAlert('Camera position has been saved');
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setSuccessAlert('');
        }, 5000);
      }

      if (event.data.polygonCoordinates) {
        const newParams = {
          centerPoint: event.data.centerPoint,
          altitude: event.data.altitude === 0 ? 0.3 : event.data.altitude,
          polygonCoordinates: JSON.stringify([
            ...event.data.polygonCoordinates,
          ]),
        };

        await updatePartialExplorer3dSettings({
          id: settings.id,
          ...newParams,
        });

        dispatch(
          updateExplorer3dSettings({
            ...settings,
            ...newParams,
          })
        );

        setSuccessAlert('Polygon coordinates have been saved');
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
          setSuccessAlert('');
        }, 5000);
      }

      if (
        event.data.polylineCoordinatesLength ||
        event.data.polylineCoordinatesLength === 0
      ) {
        setPolygonCoordinatesLength(event.data.polylineCoordinatesLength);
      }
      if (event.data.isCloseModal) {
        if (event.data.openAlert) {
          setAlertData(() => ({
            isShown: true,
            bodyText:
              'You have unsaved changes. Do you want to leave without saving?',
            closeBtnText: 'Yes, close without saving',
            handleClickCloseBtn: closeEditor,
          }));
        } else {
          closeEditor();
        }
      }
    }

    window.addEventListener('message', getChildMessage);

    return () => {
      window.removeEventListener('message', getChildMessage);
    };
  }, [closeEditor, dispatch, settings]);

  const drawPolygon = () => {
    if (drawPolygonButtonStep === 0) {
      setDrawPolygonButtonStep(1);
      setDrawPolygonButtonStepStyle('btn-success');
      iframeRef.current?.contentWindow?.postMessage({
        message: 'Draw Polygon',
        targetOrigin: '*',
      });
    } else if (drawPolygonButtonStep === 1) {
      setDrawPolygonButtonStep(2);
      setDrawPolygonButtonStepStyle('btn-danger');

      iframeRef.current?.contentWindow?.postMessage({
        message: `Build Polygon,${polygonHeight}`,
        targetOrigin: '*',
      });
    } else if (drawPolygonButtonStep === 2) {
      setDrawPolygonButtonStep(0);
      setDrawPolygonButtonStepStyle('btn-primary');
      clearCoordinates();
    }
  };

  const clearCoordinates = () => {
    iframeRef.current?.contentWindow?.postMessage({
      message: 'Clear coordinates',
      targetOrigin: '*',
    });
    setPolygonCoordinatesLength(0);
  };

  const removePrevious = () => {
    iframeRef.current?.contentWindow?.postMessage({
      message: 'Remove previous',
      targetOrigin: '*',
    });
  };

  const saveCameraPosition = () => {
    iframeRef.current?.contentWindow?.postMessage({
      message: `Save Camera Position`,
      targetOrigin: '*',
    });
  };

  const closeModalWindow = () => {
    iframeRef.current?.contentWindow?.postMessage({
      message: {
        isCloseModal: true,
        horizonAngle: settings.horizonAngle ?? 30,
        verticalAngle: settings.verticalAngle ?? 70,
        distance: settings.distance ?? 500,
        polygonCoordinates: settings.polygonCoordinates ?? '[]',
      },
      targetOrigin: '*',
    });
  };

  const saveCoordinates = () => {
    iframeRef.current?.contentWindow?.postMessage({
      message: `Save Coordinates`,
      targetOrigin: '*',
    });
  };

  const onClose = () => {
    closeModalWindow();
  };

  const handleChangeCenterPoint = useCallback(
    e => {
      if (e.target.value.length > 0) {
        const centerPoint = e.target.value.split(',').map(n => +n);

        if (iframeRef.current?.contentDocument) {
          iframeRef.current?.contentWindow?.postMessage({
            message: {
              changeCenterPoint: true,
              centerPoint,
            },
            targetOrigin: '*',
          });
        }

        dispatch(
          updateExplorer3dSettings({
            ...settings,
            centerPoint,
          })
        );
      }
    },
    [dispatch, settings]
  );

  const handleHeightChange = () => {
    setPolygonHeight(+polygonAltitudeRef.current.value);
  };

  return (
    <Modal
      show={opened}
      onHide={closeEditor}
      className="editor-modal editor-modal-start"
      fullscreen="true"
    >
      <Modal.Dialog>
        <Modal.Header>
          <Modal.Title>Map Setting</Modal.Title>
          <div className="editor-modal-header">
            <Container>
              <Field
                name="settings['centerPoint']"
                type="text"
                label="Property location"
                component={renderField}
                span={8}
                initialValue={
                  settings.centerPoint ?? [
                    DEFAULT_CENTER_LAT,
                    DEFAULT_CENTER_LNG,
                  ]
                }
                onChange={handleChangeCenterPoint}
                onUpdateStore={val => {
                  console.log('centerPoint val', val);
                  dispatch(
                    updateExplorer3dSettings({
                      ...settings,
                      centerPoint: val,
                    })
                  );
                }}
              />
            </Container>
            <div className="editor-modal-buttons">
              {drawPolygonButtonStep === 1 && (
                <Button
                  variant="primary"
                  onClick={removePrevious}
                  size="sm"
                  className="button-primary"
                  disabled={!Number(polygonCoordinatesLength)}
                >
                  <img
                    src={`${process.env.PUBLIC_URL}/img/arrow-left.svg`}
                    alt="arrow"
                  />
                </Button>
              )}
              {drawPolygonButtonStep === 1 && (
                <>
                  <Button
                    variant="primary"
                    onClick={handleHeightChange}
                    size="sm"
                  >
                    Set the Height (m)
                  </Button>
                  <input
                    type="number"
                    placeholder="0"
                    defaultValue={0}
                    min="0"
                    step="1"
                    style={{ width: '50px', textAlign: 'center' }}
                    ref={polygonAltitudeRef}
                    onChange={handleHeightChange}
                  />
                </>
              )}
              <Button
                variant="primary"
                onClick={drawPolygon}
                size="sm"
                className={drawPolygonButtonStepStyle}
                disabled={
                  drawPolygonButtonStep === 1 &&
                  (Number(polygonCoordinatesLength) < 4 ||
                    !polygonCoordinatesLength)
                }
              >
                {getDrawPolygonButtonLabel}
              </Button>
              <Button
                variant="primary"
                onClick={saveCameraPosition}
                size="sm"
                className="button-primary"
              >
                Set Camera Position
              </Button>
              <Button
                variant="primary"
                onClick={saveCoordinates}
                size="sm"
                className="button-primary"
                disabled={
                  drawPolygonButtonStep !== 2 ||
                  Number(polygonCoordinatesLength) < 4 ||
                  !polygonCoordinatesLength
                }
              >
                Save Polygon Coordinates
              </Button>
              <Button onClick={onClose} size="sm" variant="danger">
                Close
              </Button>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={'/map.html'}
            width={'100%'}
            height={'100%'}
            ref={iframeRef}
          />
        </Modal.Body>
        <Modal
          centered
          show={alertData.isShown}
          onHide={handleCloseAlert}
          backdrop="static"
          className="editor-modal-alert"
        >
          <Modal.Header closeButton>
            <p>{alertData.bodyText}</p>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={alertData.handleClickCloseBtn} variant="danger">
              {alertData.closeBtnText}
            </Button>
            <Button onClick={handleCloseAlert}>No, continue editing</Button>
          </Modal.Footer>
        </Modal>
      </Modal.Dialog>
      {successAlert && (
        <Alert
          style={{ position: 'absolute', top: 104, right: 40 }}
          variant="success"
        >
          {successAlert}
        </Alert>
      )}
    </Modal>
  );
};

export default MapSettingsPopup;
