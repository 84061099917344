import React, { useState, useEffect, useRef } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  Field,
  reduxForm,
  formValueSelector,
  initialize,
  getFormValues,
} from 'redux-form';
import IslandContainer from '../IslandContainer';
import renderField from '../../../shared/components/form/3dExplorerField';
import SelectWithSearch from '../../../shared/components/form/SelectWithSearchExplorer';
import {
  loadUnits,
  loadUnitTypes,
  loadAwsUrls,
  deleteFileFromAws,
  uploadFilesToAws,
  uploadVideosToAws,
  createToast,
} from 'redux/actions';
import ContentFileInput from '../ContentFileInput';
import AwsUrlsContainer from './AwsUrlsContainer';

const explorer3dBasePath = process.env.REACT_APP_EXPLORER3D_URL;

function ContentEditor({ isFullScreen, handleSubmit }) {
  const dispatch = useDispatch();
  const explorer3d = useSelector(state => state.explorer3d.current);

  const [currentFloorType, setCurrentFloorType] = useState(null);
  const [currentUnit, setCurrentUnit] = useState(null);
  const [currentEntityType, setCurrentEntityType] = useState('floorplans');
  const [currentTourType, setCurrentTourType] = useState('internalTour');

  const unittypes =
    useSelector(state => state.explorer3d.current.unittypes) || [];
  const units = useSelector(state => state.explorer3d.current.units) || [];

  // Define content type options
  const floorplanContentTypes = [
    { name: 'plans' },
    { name: 'images' },
    { name: 'videos' },
  ];
  const unitContentTypes = [
    { name: 'images' },
    { name: 'balconies' },
    { name: 'videos' },
  ];

  // Determine contentTypeOptions based on currentEntityType
  const contentTypeOptions =
    currentEntityType === 'floorplans'
      ? floorplanContentTypes
      : unitContentTypes;

  // Load units and unit types when we have explorer3d.id
  useEffect(() => {
    if (explorer3d?.id) {
      dispatch(loadUnits(explorer3d.id));
      dispatch(loadUnitTypes(explorer3d.id));
    }
  }, [dispatch, explorer3d?.id]);

  // Redux-form selectors
  const selector = formValueSelector('contentEditorForm');
  const contentType = useSelector(state => selector(state, 'contentType'));
  const formValues = useSelector(
    state => getFormValues('contentEditorForm')(state) || {}
  );

  // Refs to track previous type/id
  const prevEntityTypeRef = useRef();
  const prevEntityIdRef = useRef();

  // Update initial form values when entity type or floor/unit changes
  useEffect(() => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;
    const currentEntityId = current?.id;
    const prevEntityType = prevEntityTypeRef.current;
    const prevEntityId = prevEntityIdRef.current;

    if (current && currentEntityType) {
      const defaultContentType = contentTypeOptions[0].name;

      // If we changed the entity type or changed the actual entity, we re-init the form
      if (
        currentEntityType !== prevEntityType ||
        currentEntityId !== prevEntityId
      ) {
        const initValues = { ...formValues };

        // Only set contentType if it's not set or entity type changed
        if (!contentType || currentEntityType !== prevEntityType) {
          initValues.contentType = defaultContentType;
        }

        if (currentEntityType === 'floorplans') {
          initValues.floorplanType = current.name;
          initValues.floorplans_internal_tour_link = current.tourLink || '';
          initValues.floorplans_fallback_internal_tour_link =
            current.fallBackTourLink || '';
          initValues.floorplans_spinner_link = current.spinnerLink || '';
        } else if (currentEntityType === 'units') {
          // Use `internalNumber` as a string
          initValues.unitType = current.internalNumber;
          initValues.unit_internal_tour_link = current.tourLink || '';
          initValues.unit_fallback_internal_tour_link =
            current.fallBackTourLink || '';
        }

        if (Object.keys(initValues).length > 0) {
          dispatch(initialize('contentEditorForm', initValues));
        }

        // Update refs
        prevEntityTypeRef.current = currentEntityType;
        prevEntityIdRef.current = currentEntityId;
      }
    }
  }, [
    currentFloorType,
    currentUnit,
    currentEntityType,
    dispatch,
    explorer3d.id,
    contentType,
    contentTypeOptions,
    formValues,
  ]);

  // Whenever contentType changes, load new AWS URLs for that type
  useEffect(() => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;

    if (current && contentType && currentEntityType) {
      dispatch(
        loadAwsUrls(explorer3d.id, current, contentType, currentEntityType)
      );
    }
  }, [
    contentType,
    currentEntityType,
    currentFloorType,
    currentUnit,
    dispatch,
    explorer3d.id,
  ]);

  // Debug logs
  console.log('units:', units);
  useEffect(() => {
    console.log('currentUnit changed:', currentUnit);
  }, [currentUnit]);

  // Handle file deletion
  const handleDelete = url => {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;
    const locationId = explorer3d.id;
    const entityId = current?.id;
    const entityType = currentEntityType;
    const contentTypeValue = contentType;
    dispatch(
      deleteFileFromAws(url, locationId, entityId, contentTypeValue, entityType)
    );
  };

  // Helper to find the selected floorplan by name
  function getApartmentByName(unitTypes, name) {
    return unitTypes.find(type => type.name === name);
  }

  // **Fix**: Compare string to string instead of converting to Number
  function getUnitById(unitsList, val) {
    return unitsList.find(type => type.internalNumber === val);
  }

  // For downloading
  const handleClick = async (url, filename) => {
    const image = await fetch(`${explorer3dBasePath}/aws/download`, {
      method: 'POST',
      body: JSON.stringify({ url }),
      headers: { 'content-type': 'application/json' },
    });
    const imageBlob = await image.blob();
    const imageURL = URL.createObjectURL(imageBlob);
    const link = document.createElement('a');
    link.href = imageURL;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // For uploading
  async function uploadFilesHandler(files) {
    const current =
      currentEntityType === 'floorplans' ? currentFloorType : currentUnit;

    const bucketName = 'seenic-dev-content';
    const folderName = `locations/${explorer3d.id}/${currentEntityType}/${current?.id}/content/${contentType}`;

    if (!Array.from(files).length) {
      dispatch(createToast('ERROR', 'Files are not attached'));

      return;
    }

    // Replace spaces with underscores
    const modifiedFiles = Array.from(files).map(file => {
      const newFileName = file.name.replace(/\s+/g, '_');

      return new File([file], newFileName, { type: file.type });
    });

    if (contentType === 'videos') {
      try {
        await dispatch(
          uploadVideosToAws(modifiedFiles, bucketName, folderName)
        );
      } catch (error) {
        dispatch(createToast('ERROR', 'Unknown error'));
      }
    } else {
      try {
        await dispatch(uploadFilesToAws(modifiedFiles, bucketName, folderName));
      } catch (error) {
        dispatch(createToast('ERROR', 'Unknown error'));
      }
    }
  }

  // Updating the tour link for a unit
  const updateUnitTourLink = async values => {
    const current = currentUnit;
    await fetch(`${explorer3dBasePath}/units/${current?.id}/update-tour`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: values.unit_internal_tour_link,
      }),
    });
    dispatch(loadUnits(explorer3d.id));
  };

  const updateUnitFallbackTourLink = async values => {
    const current = currentUnit;
    await fetch(
      `${explorer3dBasePath}/units/${current?.id}/update-fallback-tour`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fallBackTourLink: values.unit_fallback_internal_tour_link,
        }),
      }
    );
    dispatch(loadUnits(explorer3d.id));
  };

  // Updating the tour link for a floorplan
  const updateFloorplanTourLink = async values => {
    const current = currentFloorType;
    await fetch(`${explorer3dBasePath}/unit-types/${current?.id}/update-tour`, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: values.floorplans_internal_tour_link,
      }),
    });
    dispatch(loadUnitTypes(explorer3d.id));
  };

  const updateFloorplanFallbackTourLink = async values => {
    const current = currentFloorType;
    await fetch(
      `${explorer3dBasePath}/unit-types/${current?.id}/update-fallback-tour`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fallBackTourLink: values.floorplans_fallback_internal_tour_link,
        }),
      }
    );
    dispatch(loadUnitTypes(explorer3d.id));
  };

  // Updating the 360 spinner link for a floorplan
  const updateFloorplan360Link = async values => {
    const current = currentFloorType;
    await fetch(
      `${explorer3dBasePath}/unit-types/${current?.id}/update-spinner`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          link: values.floorplans_spinner_link,
        }),
      }
    );
    dispatch(loadUnitTypes(explorer3d.id));
  };

  return (
    <>
      <Container className="no-padding" fluid={isFullScreen}>
        <IslandContainer isFullScreen={isFullScreen} className="drop-container">
          <h2 className="content-label">Content Editor</h2>
          <Container className="content-editor-item">
            <h2 className="content-description">Entity type</h2>
            <Container className="gap-1 flex-column d-flex">
              <Form.Check
                className="d-flex flex-row align-items-center form-check-label"
                type="radio"
                id="floorplans"
                label="Floorplans"
                name="entityType"
                value="floorplans"
                checked={currentEntityType === 'floorplans'}
                onChange={() => {
                  setCurrentEntityType('floorplans');
                  setCurrentFloorType(null);
                  setCurrentUnit(null);
                }}
              />
              <Form.Check
                className="d-flex flex-row align-items-center form-check-label"
                type="radio"
                id="units"
                label="Units"
                name="entityType"
                value="units"
                checked={currentEntityType === 'units'}
                onChange={() => {
                  setCurrentEntityType('units');
                  setCurrentFloorType(null);
                  setCurrentUnit(null);
                }}
              />
            </Container>
          </Container>

          {/* Floorplan Selector */}
          {currentEntityType === 'floorplans' && (
            <Field
              name="floorplanType"
              label="Floorplan type"
              options={unittypes.map((entity, index) => ({
                name: entity.name,
                value: entity.name,
                key: `${index}c`,
              }))}
              component={SelectWithSearch}
              onChange={value => {
                const selected = getApartmentByName(unittypes, value);
                setCurrentFloorType(selected);
              }}
            />
          )}

          {/* Unit Selector */}
          {currentEntityType === 'units' && (
            <Field
              name="unitType"
              label="Unit type"
              options={units.map((entity, index) => ({
                // internalNumber is a string, so keep it as a string
                name: entity.internalNumber,
                value: entity.internalNumber,
                key: `${index}c`,
              }))}
              component={SelectWithSearch}
              onChange={value => {
                const selected = getUnitById(units, value);
                console.log('Selected value:', value);
                setCurrentUnit(selected);
              }}
            />
          )}

          {/* Content Type Selector */}
          {(currentFloorType || currentUnit) && (
            <Field
              name="contentType"
              label="Content type"
              options={contentTypeOptions.map((entity, index) => ({
                name: entity.name,
                value: entity.name,
                key: `${index}c`,
              }))}
              component={SelectWithSearch}
            />
          )}

          {/* Floorplans - 360 Spinner Link */}
          {currentEntityType === 'floorplans' &&
            currentFloorType &&
            contentType &&
            currentTourType === 'internalTour' && (
              <>
                <Field
                  name="floorplans_spinner_link"
                  type="text"
                  label="360 Spinner link"
                  component={renderField}
                />
                <Button
                  className="btn-primary"
                  onClick={handleSubmit(updateFloorplan360Link)}
                >
                  Update 360 Floorplan Link
                </Button>
              </>
            )}

          {/* Floorplans - Tour Link */}
          {currentEntityType === 'floorplans' &&
            currentFloorType &&
            contentType && (
              <>
                <Field
                  name="floorplans_internal_tour_link"
                  type="text"
                  label="Tour link"
                  component={renderField}
                />
                <Button
                  className="btn-primary"
                  onClick={handleSubmit(updateFloorplanTourLink)}
                >
                  Update Tour Link
                </Button>
              </>
            )}

          {/* Floorplans - Tour Link */}
          {currentEntityType === 'floorplans' &&
            currentFloorType &&
            contentType && (
              <>
                <Field
                  name="floorplans_fallback_internal_tour_link"
                  type="text"
                  label="Fallback Tour link"
                  component={renderField}
                />
                <Button
                  className="btn-primary"
                  onClick={handleSubmit(updateFloorplanFallbackTourLink)}
                >
                  Update Fallback Tour Link
                </Button>
              </>
            )}

          {/* Units - Internal Tour Link */}
          {currentEntityType === 'units' &&
            currentUnit &&
            contentType &&
            currentTourType === 'internalTour' && (
              <>
                <Field
                  name="unit_internal_tour_link"
                  type="text"
                  label="Tour link"
                  component={renderField}
                />
                <Button
                  className="btn-primary"
                  onClick={handleSubmit(updateUnitTourLink)}
                >
                  Update Tour Link
                </Button>
              </>
            )}

          {currentEntityType === 'units' &&
            currentUnit &&
            contentType &&
            currentTourType === 'internalTour' && (
              <>
                <Field
                  name="unit_fallback_internal_tour_link"
                  type="text"
                  label="Fallback Tour link"
                  component={renderField}
                />
                <Button
                  className="btn-primary"
                  onClick={handleSubmit(updateUnitFallbackTourLink)}
                >
                  Update Fallback Tour Link
                </Button>
              </>
            )}
        </IslandContainer>
      </Container>

      <Container className="no-padding" fluid={isFullScreen}>
        {currentEntityType &&
          ((currentEntityType === 'floorplans' && currentFloorType) ||
            (currentEntityType === 'units' && currentUnit)) &&
          contentType && (
            <IslandContainer
              isFullScreen={isFullScreen}
              className="drop-container"
            >
              <h2 className="content-label">Content / Gallery</h2>
              <ContentFileInput
                buttonLabel="Upload Content"
                isMultiple={true}
                onSubmit={uploadFilesHandler}
              />
            </IslandContainer>
          )}
        {currentEntityType &&
          ((currentEntityType === 'floorplans' && currentFloorType) ||
            (currentEntityType === 'units' && currentUnit)) &&
          contentType && (
            <IslandContainer isFullScreen={isFullScreen}>
              <AwsUrlsContainer
                entityId={
                  currentEntityType === 'floorplans'
                    ? currentFloorType?.id
                    : currentUnit?.id
                }
                contentType={contentType}
                handleDelete={handleDelete}
                handleClick={handleClick}
                isUnit={currentEntityType === 'units'}
                uploadPreviewId={
                  currentEntityType === 'units'
                    ? currentUnit.id
                    : currentFloorType.id
                }
              />
            </IslandContainer>
          )}
      </Container>

      <style jsx>{`
        .content-label {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 16px;
        }
        .content-description {
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 8px;
        }
        .content-editor-item {
          margin-bottom: 24px;
        }
        .drop-container {
          padding: 16px !important;
        }
        .image-grid {
          display: flex;
          flex-wrap: wrap;
          gap: 16px;
          justify-content: flex-start;
        }
        .image-item {
          flex: 1 1 calc(33.333% - 16px);
          max-width: calc(33.333% - 16px);
          box-sizing: border-box;
          position: relative;
          height: 260px;
        }
        .image-item img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        @media (max-width: 768px) {
          .image-item {
            flex: 1 1 calc(50% - 16px);
            max-width: calc(50% - 16px);
          }
        }
        @media (max-width: 480px) {
          .image-item {
            flex: 1 1 100%;
            max-width: 100%;
          }
        }
      `}</style>
    </>
  );
}

export default reduxForm({
  form: 'contentEditorForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ContentEditor);
