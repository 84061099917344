import React from 'react';

export const Google = ({ className, color = 'var(--content-accent)' }) => {
  return (
    <svg
      className={`google ${className}`}
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="google">
        <path
          d="M21 11.9995C21.0001 14.1187 20.2524 16.1699 18.8887 17.7919C17.5249 19.414 15.6325 20.5027 13.5448 20.8664C11.4571 21.2302 9.30798 20.8456 7.47601 19.7803C5.64404 18.7151 4.24671 17.0376 3.53014 15.0433C2.81357 13.049 2.82371 10.8657 3.55879 8.87816C4.29387 6.89058 5.70672 5.22613 7.54851 4.17798C9.3903 3.12983 11.5429 2.7652 13.6271 3.14832C15.7114 3.53145 17.5935 4.63774 18.9422 6.27236C19.0091 6.3477 19.0602 6.43576 19.0924 6.53127C19.1245 6.62679 19.1371 6.72782 19.1294 6.8283C19.1216 6.92879 19.0937 7.02669 19.0472 7.11614C19.0008 7.20559 18.9368 7.28477 18.8591 7.34894C18.7814 7.41311 18.6915 7.46095 18.5949 7.48962C18.4983 7.51828 18.3968 7.52717 18.2967 7.51577C18.1966 7.50436 18.0998 7.47289 18.012 7.42323C17.9243 7.37357 17.8475 7.30674 17.7863 7.22674C16.6832 5.88922 15.1508 4.97518 13.4497 4.64009C11.7487 4.30501 9.98416 4.56959 8.45621 5.38881C6.92827 6.20804 5.73133 7.53132 5.06902 9.13353C4.40671 10.7357 4.31994 12.5179 4.82346 14.1769C5.32699 15.8359 6.38971 17.2692 7.83083 18.233C9.27196 19.1968 11.0025 19.6315 12.728 19.4633C14.4535 19.295 16.0675 18.5342 17.2953 17.3102C18.5231 16.0861 19.2889 14.4745 19.4625 12.7495H12C11.8011 12.7495 11.6103 12.6705 11.4697 12.5299C11.329 12.3892 11.25 12.1985 11.25 11.9995C11.25 11.8006 11.329 11.6099 11.4697 11.4692C11.6103 11.3286 11.8011 11.2495 12 11.2495H20.25C20.4489 11.2495 20.6397 11.3286 20.7803 11.4692C20.921 11.6099 21 11.8006 21 11.9995Z"
          fill={`${color}`}
        />
      </g>
    </svg>
  );
};
