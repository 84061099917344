import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from 'styled-components';
import * as yup from 'yup';
import { Formik } from 'formik';

import { loginWithSession, loginWithGoogle, loginWithEmail } from './auth';
import Layout from '../Layout/Layout';
import { authenticate, hideContextMenu } from '../../redux/actions';
import { magic } from '../../shared/utils/magic';
import { ArrowRight } from '../../shared/components/Icons/ArrowRight';
import { Google } from '../../shared/components/Icons/Google';

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--surface-pale);
`;

const ShadowBox = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow:
    0 36px 80px rgba(23, 28, 38, 0.05),
    0 13.141px 29.201px rgba(23, 28, 38, 0.03),
    0 6.38px 14.177px rgba(23, 28, 38, 0.03),
    0 3.127px 6.95px rgba(23, 28, 38, 0.02),
    0 1.237px 2.748px rgba(23, 28, 38, 0.02);
  border-radius: var(--utilities-border-radius-xl);
  background-color: var(--surface-default);
`;

const TopContainer = styled.div`
  display: flex;
  padding: var(--utilities-size-1-500);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--utilities-size-3-000);
  align-self: stretch;
`;

const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--utilities-size-1-000);
  align-self: stretch;
`;

const Header = styled.div`
  color: var(--content-primary);
  font-family: var(--typography-font-family-default);
  font-size: var(--typography-font-size-sm);
  font-style: normal;
  font-weight: 500;
  line-height: var(--typography-line-height-sm-line);
  letter-spacing: var(--typography-letter-spacing-default);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  height: var(--utilities-size-2-500);
  min-height: var(--utilities-size-2-500);
  border: 1px solid var(--border-default);
  border-radius: var(--utilities-border-radius-default);
  padding: var(--utilities-size-0-500) var(--utilities-size-1-000);
  font-family: var(--typography-font-family-default) !important;
  font-size: var(--typography-font-size-md);
  font-style: normal;
  font-weight: 400;
  line-height: var(--typography-line-height-md-line);
  letter-spacing: var(--typography-letter-spacing-default);
  color: var(--content-primary);
  margin-top: -4px;
  width: 100%;

  &:hover {
    outline: none;
    border-color: var(--border-hover-control-default-hover);
  }
  &:focus {
    outline: none;
    border: 2px solid var(--border-hover-control-default-hover);
  }
`;

const ContinueButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  height: var(--utilities-size-2-500);
  min-height: var(--utilities-size-2-500);
  margin-top: var(--utilities-size-1-000);
  background-color: var(--surface-accent);
  border: 1px;
  text-align: center;
  cursor: pointer;
  border-radius: var(--utilities-border-radius-default);
  text-transform: none;
  align-items: center;

  &:hover {
    background-color: var(--surface-hover-accent-hover);
  }

  &:disabled {
    background-color: var(--surface-accent);
    color: var(--content-inverted-weak) !important;
    cursor: not-allowed;
    pointer-events: unset;
    &:hover {
      background-color: var(--surface-accent);
    }
  }
`;

const ContinueButtonText = styled.div`
  color: #ffffff;
  text-align: center;
  font-family: var(--typography-font-family-default) !important;
  font-size: var(--typography-font-size-md);
  font-style: normal;
  font-weight: 500;
  line-height: var(--typography-line-height-md-line);
  letter-spacing: var(--typography-letter-spacing-default);
`;

const ArrowRights = styled(ArrowRight)`
  width: var(--utilities-size-1-250);
  height: var(--utilities-size-1-250);
  margin-left: var(--utilities-size-0-500);
  color: var(--content-inverted);
`;

const GoogleButtonContainer = styled.div`
  display: flex;
  padding: var(--utilities-size-1-500);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--utilities-size-1-000);
  align-self: stretch;
  border-top: 1px solid var(--border-default);
  background: var(--surface-pale);
  border-bottom-left-radius: var(--utilities-border-radius-xl);
  border-bottom-right-radius: var(--utilities-border-radius-xl);
`;

const Text = styled.div`
  color: var(--content-primary);
  font-family: var(--typography-font-family-default) !important;
  font-size: var(--typography-font-size-sm);
  font-style: normal;
  font-weight: 500;
  line-height: var(--typography-line-height-sm-line);
  letter-spacing: var(--typography-letter-spacing-default);
`;

const GoogleLogInButton = styled.button`
  width: 100%;
  height: var(--utilities-size-2-500);
  cursor: pointer;
  display: flex;
  min-height: var(--utilities-size-2-500);
  padding: var(--utilities-size-0-500) var(--utilities-size-1-000);
  justify-content: center;
  align-items: center;
  gap: var(--utilities-size-0-500);
  align-self: stretch;
  border-radius: var(--utilities-border-radius-default);
  border: 1px solid var(--border-accent-weak);
  background: var(--surface-accent-tint);
  box-shadow: var(--shadows-subtle);
  color: var(--content-accent);

  &:hover {
    border: 1px solid var(--border-hover-accent-weak-hover);
    background: var(--surface-hover-accent-tint-hover);
  }
`;

const StyledGoogleIcon = styled(Google)`
  width: var(--utilities-size-1-250);
  height: var(--utilities-size-1-250);
`;

const GoogleButtonText = styled.div`
  text-align: center;
  font-family: var(--typography-font-family-default) !important;
  font-size: var(--typography-font-size-md);
  font-style: normal;
  font-weight: 500;
  line-height: var(--typography-line-height-md-line);
  letter-spacing: var(--typography-letter-spacing-default);
`;

const ErrorTextContainer = styled.div`
  display: flex;
  padding: var(--utilities-size-0-500) var(--utilities-size-0-500)
    var(--utilities-size-0-500) var(--utilities-size-1-000);
  align-items: center;
  gap: var(--utilities-size-1-000);
  align-self: stretch;
  border-radius: var(--utilities-border-radius-default);
  border: 1px solid var(--border-danger-weak);
  background: var(--surface-danger-tint);
  margin-bottom: var(--utilities-size-1-500);
`;

const ErrorText = styled.div`
  color: var(--content-danger);
  font-family: var(--typography-font-family-default) !important;
  font-size: var(--typography-font-size-sm);
  font-style: normal;
  font-weight: 500;
  line-height: var(--typography-line-height-sm-line);
  letter-spacing: var(--typography-letter-spacing-default);
`;

function MagicAuth({ children, dispatch }) {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    loginWithSession()
      .then(user => {
        if (user) {
          setIsLoggedIn(true);
          dispatch(authenticate(user));

          return;
        }

        return loginWithGoogle();
      })
      .then(user => {
        if (user) {
          setIsLoggedIn(true);
          dispatch(authenticate(user));
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [dispatch]);

  useEffect(() => {
    const listener = e => {
      if (!e.composedPath().find(el => el.className === 'context-menu')) {
        dispatch(hideContextMenu());
      }
    };
    document.addEventListener('click', listener);

    return () => document.removeEventListener('click', listener);
  }, [dispatch]);

  if (loading) {
    return (
      <Container>
        <div style={{ fontSize: '16px', fontWeight: 'bold' }}>Loading...</div>
      </Container>
    );
  }

  if (isLoggedIn) {
    return <Layout>{children}</Layout>;
  }

  const handleEmailLogin = async (values, { setSubmitting }) => {
    setLoading(true);
    try {
      const user = await loginWithEmail(values.email);
      if (user) {
        setIsLoggedIn(true);
        dispatch(authenticate(user));
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setSubmitting(false);
    }
  };

  const handleSocialLogin = async event => {
    event.preventDefault();
    setLoading(true);
    try {
      await magic.oauth.loginWithRedirect({
        provider: 'google',
        redirectURI: new URL('/dashboard', window.location.origin).href,
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Container>
      <ShadowBox>
        <TopContainer>
          <img
            src={`${process.env.PUBLIC_URL}/seenic-logo-color-simple.svg`}
            alt="Company logo"
          />
          <EmailContainer>
            <Header>Welcome back!</Header>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={yup.object().shape({
                email: yup
                  .string()
                  .email(
                    'You entered an incorrect email address. Please enter a real address.'
                  )
                  .max(50),
              })}
              onSubmit={handleEmailLogin}
            >
              {({ errors, handleChange, handleSubmit, values }) => (
                <Form onSubmit={handleSubmit} autoComplete="off">
                  {errors.email && (
                    <ErrorTextContainer>
                      <ErrorText>{errors.email}</ErrorText>
                    </ErrorTextContainer>
                  )}
                  <Input
                    type="email"
                    name="email"
                    // placeholder="Enter your email address"
                    required
                    error={Boolean(errors.email)}
                    onChange={e => {
                      handleChange(e);
                      setEmail(e.currentTarget.value);
                    }}
                    value={values.email}
                  />
                  <ContinueButton disabled={errors.email} type="submit">
                    <ContinueButtonText>Continue</ContinueButtonText>
                    <ArrowRights />
                  </ContinueButton>
                </Form>
              )}
            </Formik>
          </EmailContainer>
        </TopContainer>
        <GoogleButtonContainer>
          <Text>or use your account</Text>
          <GoogleLogInButton onClick={handleSocialLogin}>
            <StyledGoogleIcon />
            <GoogleButtonText>Continue with Google</GoogleButtonText>
          </GoogleLogInButton>
        </GoogleButtonContainer>
      </ShadowBox>
    </Container>
  );
}

MagicAuth.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect()(MagicAuth);
